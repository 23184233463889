import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ContactForm = makeShortcode("ContactForm");
const TextArea = makeShortcode("TextArea");
const TextColumn = makeShortcode("TextColumn");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="contact/about-me.png" imageAlt="My hobbies" mdxType="TextMediaSplit">   
      <h1>{`Contact Me`}</h1>
      <p>{`Emily is a multi-disciplined graphic designer focussing in the field Graphic Design for film, television, theatre and prop making although is constantly experimenting in various fields.`}</p>
      <p>{`Emily’s work has been showcased around Edinburgh during their ‘Giraffe About Town’ 2022 event, had group work shortlisted for the Creative Conscious Design Award 2022 and has had work exhibited at the ‘Note to Self’ exhibition hosted by Glimpse in 2023.`}</p>
      <p>{`An avid musical theatre fan, she is guaranteed to be rocking out to show tunes whilst designing...it’s cooler than it sounds. When not designing, Emily can be found hanging upside down from an aerial hoop, reading or cooing over animals great and small.`}</p>
      <p>{`If you are interested in Emily’s work, please use the contact form below to get in touch.`}</p>
      <br /> 
      <h2>{`Let's Talk`}</h2>
      <ContactForm mdxType="ContactForm" />
    </TextMediaSplit>
    <br />
    <TextArea mdxType="TextArea">
      <TextColumn cols="2" mdxType="TextColumn">
        <h2>{`Qualifications/Courses`}</h2>
        <table>
    <tbody>
        <tr>
            <td>
                <p>Undergraduate Degree - Graphic Design (Currently ongoing)<br />
                <small>Oxford Brookes University</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2020 - 2023</td>
        </tr>
         <tr>
            <td>
                <p>Modern Calligraphy<br />
                <small>Wallice Lettering</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2023</td>
        </tr>
        <tr>
            <td><p>Joby Carter’s Learn About Letters - Online Signwriting Course</p></td>
            <td style={{
                "textAlign": "right"
              }}>2022</td>
        </tr>
        <tr>
            <td><p>Joby Carter’s Fancy Lettering - Online Signwriting Course</p></td>
            <td style={{
                "textAlign": "right"
              }}>2022</td>
        </tr>
        <tr>
            <td>
                <p>Prop Making Essentials<br />
                <small>Creative Media Skills Institute</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2022</td>
        </tr>
    </tbody>
        </table>
      </TextColumn>
      <TextColumn cols="2" mdxType="TextColumn">
        <h2>{`Experience`}</h2>
        <table>
    <tbody>
        <tr>
            <td>
                <p>Spin City Newbury<br />
                <small>Social Media Coordinator</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2022</td>
        </tr>
        <tr>
            <td>
                <p>Set Blue Scenery<br />
                <small>Scenic Artist - Freelance</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2022</td>
        </tr>
        <tr>
            <td>
                <p>Richard Nutbourne Scenic Studio<br />
                <small>Scenic Artist - Work experience</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2021</td>
        </tr>
    </tbody>
        </table>
      </TextColumn>
    </TextArea>
    <TextArea mdxType="TextArea">
      <TextColumn divider="top" mdxType="TextColumn">
        <h2>{`Awards`}</h2>
      </TextColumn>
      <TextColumn cols="2" mdxType="TextColumn">
        <table>
    <tbody>
        <tr>
            <td>
                <p>Selected Work for 'Note To Self' Exhibition<br />
                <small>Glimpse</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2023</td>
        </tr>
         <tr>
            <td>
                <p>Shortlisted for Creative Conscience Awards 22<br />
                <small>Creative Conscience</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2022</td>
        </tr>
    </tbody>
        </table>
      </TextColumn>
      <TextColumn cols="2" mdxType="TextColumn">
        <table>
    <tbody>
         <tr>
            <td>
                <p>Winning Design for the Giraffe About Town Trail<br />
                <small>The Royal Zoological Society of Scotland</small></p>
            </td>
            <td style={{
                "textAlign": "right"
              }}>2022</td>
        </tr>
    </tbody>
        </table>
      </TextColumn>
    </TextArea>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      